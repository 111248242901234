<template>
  <section id="section-list-service" class="pt-0">
    <div class="nstra-container">
      <div class="section-body">
        <div
          id="service-section-content"
          ref="service-section-content"
          v-bind:class="{
            'fixed-tabs': pageOffset < 50 && servicesContentOffset > 50,
          }"
        >
          <div
            id="service-tab"
            ref="service-tab"
            class="service-tab"
            v-bind:class="{
              'fixed-top': pageOffset < 50 && servicesContentOffset > 50,
            }"
          >
            <b-button
              class="service-btn d-flex justify-content-start align-items-center bg-transparent monochrome-black bold"
              v-bind:class="{ active: this.activeMenu == 'mobile-development' }"
              v-scroll-to="{
                el: '#mobile-development',
                offset: -this.tabHeight - 35,
                ...scrollOptions,
              }"
            >
              <img class="service-btn-icon" src="@/assets/icons/ico-service-mobile.svg" alt="digital product development icon" />
              Digital Product Development
            </b-button>
            <b-button
              class="service-btn d-flex justify-content-start align-items-center bg-transparent monochrome-black bold"
              v-bind:class="{ active: this.activeMenu == 'cloud' }"
              v-scroll-to="{
                el: '#cloud',
                offset: -this.tabHeight - 35,
                ...scrollOptions,
              }"
            >
              <img class="service-btn-icon" src="@/assets/icons/ico-service-cloud.svg" alt="devops solution icon" />
              DevOps Solution
            </b-button>
            <b-button
              class="service-btn d-flex justify-content-start align-items-center bg-transparent monochrome-black bold"
              v-bind:class="{ active: this.activeMenu == 'testing' }"
              v-scroll-to="{
                el: '#testing',
                offset: -this.tabHeight - 35,
                ...scrollOptions,
              }"
            >
              <img class="service-btn-icon" src="@/assets/icons/ico-service-testing.svg" alt="testing and qa icon" />
              Testing &amp; QA
            </b-button>
            <b-button
              class="service-btn d-flex justify-content-start align-items-center bg-transparent monochrome-black bold"
              v-bind:class="{ active: this.activeMenu == 'web-development' }"
              v-scroll-to="{
                el: '#web-development',
                offset: -this.tabHeight - 35,
                ...scrollOptions,
              }"
            >
              <img class="service-btn-icon" src="@/assets/icons/ico-service-web.svg" alt="application support icon" />
              Application Support
            </b-button>
          </div>
          <div class="service-tab-contents">
            <div class="service-content mb-5" id="mobile-development">
              <div v-scrollanimation="'up'" class="service-description">
                <b-row cols-sm="1">
                  <b-col class="service-content-desc-wrap" sm="12" lg="6">
                    <div class="service-content-desc bg-green-super-light-green text-left">
                      <h3 class="service-content-title">
                        Digital Product Development
                      </h3>
                      <p class="service-content-desc-text">
                        Working together to define strategic business objectives for your digital initiatives, we will build custom applications using the appropriate technologies to deliver a
                        seamless experience for your and your customers.
                      </p>
                    </div>
                  </b-col>
                  <b-col class="d-flex align-items-start justify-content-start" sm="12" lg="6">
                    <img
                      class="service-content-image reguler-shadow reguler-border-radius"
                      src="@/assets/images/services/img-service-5.jpeg"
                      alt="digital product development image"
                    />
                  </b-col>
                </b-row>
              </div>
              <div v-scrollanimation="'up'" class="service-subcontent">
                <b-row>
                  <b-col class="text-left" md="6">
                    <h4 class="service-subcontent-title">
                      Web Application Development
                    </h4>
                    <p class="service-subcontent-desc-text">
                      Build web-based applications with open source frameworks such as Angular, Reactjs and Vue js to build scalable and secure web applications including APIs.
                    </p>
                  </b-col>
                  <b-col class="text-left" md="6">
                    <h4 class="service-subcontent-title">
                      Mobile Application Development
                    </h4>
                    <p class="service-subcontent-desc-text">
                      Build mobile-based applications in both native and hybrid languages such as swift, kotlin and react native to deliver applications for iOS and Android devices.
                    </p>
                  </b-col>
                </b-row>
              </div>
              <div class="service-technologies bg-monochrome-grey-super-light-grey ignore-nstra-container mt-5">
                <div class="nstra-container text-left">
                  <h4 v-scrollanimation="'left'" class="service-subcontent-title">
                    Technology We Use
                  </h4>
                  <div v-scrollanimation="'left'" class="technologies d-flex flex-row align-items-center">
                    <div class="technology d-flex flex-row align-items-center">
                      <img width="40" height="40" class="technology-image" src="@/assets/images/logo-swift.png" alt="swift" />
                      <span>Swift</span>
                    </div>
                    <div class="technology d-flex flex-row align-items-center">
                      <img width="40" height="40" class="technology-image" src="@/assets/images/logo-kotlin.png" alt="kotlin" />
                      <span>Kotlin</span>
                    </div>
                    <div class="technology d-flex flex-row align-items-center">
                      <img width="40" height="40" class="technology-image" src="@/assets/images/logo-react.png" alt="react native" />
                      <span>React Native</span>
                    </div>
                    <div class="technology d-flex flex-row align-items-center">
                      <img width="40" height="40" class="technology-image" src="@/assets/images/logo-springboot.png" alt="java springboot" />
                      <span>Java Spring Boot</span>
                    </div>
                  </div>
                  <div v-scrollanimation="'left'" class="technologies d-flex flex-row align-items-center mt-4">
                    <div class="technology d-flex flex-row align-items-center">
                      <img width="40" height="40" class="technology-image" src="@/assets/images/logo-angular.png" alt="angular" />
                      <span>Angular</span>
                    </div>
                    <div class="technology d-flex flex-row align-items-center">
                      <img width="40" height="40" class="technology-image" src="@/assets/images/logo-react.png" alt="react" />
                      <span>React</span>
                    </div>
                    <div class="technology d-flex flex-row align-items-center">
                      <img width="40" height="40" class="technology-image" src="@/assets/images/logo-vue.png" alt="vue" />
                      <span>Vue</span>
                    </div>
                    <div class="technology d-flex flex-row align-items-center">
                      <img width="40" height="40" class="technology-image" src="@/assets/images/logo-zk.png" alt="zk" />
                      <span>ZK</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="service-content" id="cloud">
              <div v-scrollanimation="'up'" class="service-description">
                <b-row>
                  <b-col class="service-content-desc-wrap" sm="12" lg="6">
                    <div class="service-content-desc bg-green-super-light-green text-left">
                      <h3 class="service-content-title">
                        DevOps Solution
                      </h3>
                      <p class="service-content-desc-text">
                        With our certified cloud engineer, we are more than automation. Our capabilities include solution architect, cloud system implementation and monitoring.
                      </p>
                    </div>
                  </b-col>
                  <b-col class="d-flex align-items-start justify-content-start" sm="12" lg="6">
                    <img class="service-content-image reguler-shadow reguler-border-radius" src="@/assets/images/services/img-service-6.jpeg" alt="devops solution image" />
                  </b-col>
                </b-row>
              </div>
              <div v-scrollanimation="'up'" class="service-subcontent">
                <b-row>
                  <b-col class="text-left" md="6">
                    <h4 class="service-subcontent-title">
                      Solution Architect
                    </h4>
                    <p class="service-subcontent-desc-text">
                      Your consulting partner for all your application architecture design needs. Selecting the most appropriate technology, our solution architect will balance the need and concerns
                      of the project with the concerns of the business.
                    </p>
                  </b-col>
                  <b-col class="text-left" md="6">
                    <h4 class="service-subcontent-title">
                      Implementation Solution
                    </h4>
                    <p class="service-subcontent-desc-text">
                      Ensuring the right configuration environment to build pipeline integration for application build process automatically
                    </p>
                  </b-col>
                </b-row>
              </div>
              <div class="service-technologies bg-monochrome-grey-super-light-grey ignore-nstra-container my-5">
                <div class="nstra-container text-left">
                  <h4 v-scrollanimation="'left'" class="service-subcontent-title">
                    Technology We Use
                  </h4>
                  <div v-scrollanimation="'left'" class="technologies d-flex flex-row align-items-center">
                    <div class="technology d-flex flex-row align-items-center">
                      <img width="105" height="40" class="technology-image" src="@/assets/images/GC-Partner.png" alt="google cloud partner" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="service-content my-5" id="testing">
              <div v-scrollanimation="'up'" class="service-description">
                <b-row>
                  <b-col class="service-content-desc-wrap" sm="12" lg="6">
                    <div class="service-content-desc bg-green-super-light-green text-left">
                      <h3 class="service-content-title">
                        Testing &amp; QA
                      </h3>
                      <p class="service-content-desc-text">
                        With years of experience, creative testing scenarios, rigorous manual testing and test automation helps to delivery quality digital products and ensures system will function as
                        designed.
                      </p>
                    </div>
                  </b-col>
                  <b-col class="d-flex align-items-start justify-content-start" sm="12" lg="6">
                    <img
                      class="service-content-image reguler-shadow reguler-border-radius"
                      src="@/assets/images/services/img-service-7.jpeg"
                      alt="testing and qa image"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="service-content mb-5" id="web-development">
              <div v-scrollanimation="'up'" class="service-description">
                <b-row>
                  <b-col class="service-content-desc-wrap" sm="12" lg="6">
                    <div class="service-content-desc bg-green-super-light-green text-left">
                      <h3 class="service-content-title">
                        Application Support
                      </h3>
                      <p class="service-content-desc-text">
                        A successful application is a continuous and ongoing process. Our team of experts will gather feedback, reports and utilization analysis to help modify the existing system as
                        needed.
                      </p>
                    </div>
                  </b-col>
                  <b-col class="d-flex align-items-start justify-content-start" sm="12" lg="6">
                    <img class="service-content-image reguler-shadow reguler-border-radius" src="@/assets/images/services/img-service-8.jpeg" alt="application support image" />
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

import { BRow, BCol, BButton } from "bootstrap-vue";

export default {
  name: "ServiceList",
  components: {
    BRow,
    BCol,
    BButton
  },
  data: function() {
    return {
      pageOffset: 0,
      sectionOffset: 0,
      servicesContentOffset: 0,
      tabHeight: 0,
      lastScrollPosition: 0,
      disableShowNavbar: false,
      activeMenu: "",
      scrollOptions: {
        easing: "ease-in",
        force: true,
        cancelable: true,
        onStart: () => {
          this.disableShowNavbar = true;
        },
        onDone: () => {
          setTimeout(() => {
            this.disableShowNavbar = false;
          }, 500);
        },
        x: false,
        y: true,
      },
    };
  },
  mounted() {
    const tabElement = this.$refs["service-tab"];
    this.tabHeight = tabElement.getBoundingClientRect()?.height + tabElement.getBoundingClientRect()?.y;

    this.lastScrollPosition = window.pageYOffset;
    window.addEventListener("scroll", this.onScroll, true);
  },
  methods: {
    onScroll: function(e) {
      const sectionElement = this.$refs.services,
        contentElement = this.$refs["service-section-content"],
        tabElement = this.$refs["service-tab"];

      this.sectionOffset = sectionElement?.getBoundingClientRect()?.top;
      this.pageOffset = contentElement?.getBoundingClientRect()?.top;
      this.servicesContentOffset = contentElement?.offsetHeight + this.pageOffset;

      this.tabHeight = tabElement?.getBoundingClientRect()?.height;

      if (this.pageOffset < 50 && this.servicesContentOffset > 50) {
        this.$refs["service-tab"].style.marginTop =
          e?.srcElement?.scrollTop < this.lastScrollPosition && !this.disableShowNavbar ? document.getElementsByClassName("navbar")[0].offsetHeight + "px" : 0;
      }

      this.lastScrollPosition = e?.srcElement?.scrollTop;
      this.checkActiveMenu();
    },
    checkActiveMenu: function() {
      const menus = ["web-development", "mobile-development", "testing", "cloud"];
      const tabHeight = this?.$refs["service-tab"]?.offsetHeight;

      this.activeMenu = "";
      menus.forEach((menu) => {
        const section = document.getElementById(menu),
          sectionYOffset = section?.getBoundingClientRect()?.top,
          contentOffset = section?.offsetHeight + sectionYOffset;

        this.activeMenu = sectionYOffset - tabHeight - 35 < 1 && contentOffset - tabHeight - 35 > 1 ? menu : this.activeMenu;
      });
    },
  },
};
</script>
